<template>
  <div class="flex ju-between wrap">
    <div v-for="opt in item.subItemList" :key="opt.id" class="optBox">
      <div class="flex-d ju-center itemBox al-center m-t1 m-b1 cur-p" @click="openModal(opt)">
        <img :src="require(`@/assets/images/businessCenter/fun${opt.pageType}.png`)" alt="">
        <div class="m-t1"> {{ opt.title }}</div>
      </div>
    </div>
    <!-- <a-modal :title="title" v-model:visible="inquiryVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <Inquiry />
    </a-modal> -->
    <a-modal :title="title" v-model:visible="findCustomerVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <FindCustomer />
    </a-modal>
    <!-- <a-modal :title="title" v-model:visible="documentaryVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <Documentary :type="99" />
    </a-modal> -->
    <a-modal :title="title" v-model:visible="addCustomerVisible" :destroyOnClose="true" width="30%" @ok="AddCustomerOk">
      <AddCustomer ref="addCustomer" @success="addCustomerVisible = false" />
    </a-modal>
    <a-modal :title="title" v-model:visible="temporaryVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <TemporaryCustomer />
    </a-modal>
    <a-modal :title="title" v-model:visible="quantityDashboardVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <QuantityDashboard />
    </a-modal>
     <!-- 指标看板 -->
     <IndicatorModule v-if="IndicatorVisible"  v-model:visible="IndicatorVisible" ></IndicatorModule>
     
    <a-modal :title="title" v-model:visible="orderRefundVisible" :destroyOnClose="true" width="40%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <OrderRefund />
    </a-modal>
    <a-modal :title="title" v-model:visible="orderInvoiceVisible" :destroyOnClose="true" width="40%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <OrderInvoice />
    </a-modal>
    <a-modal :title="title" v-model:visible="channelVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <ChannelConversion />
    </a-modal>
    <a-modal :title="title" v-model:visible="prescriptionVisible" centered :destroyOnClose="true" width="96%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <Prescription />
    </a-modal>
    <a-modal :title="title" v-model:visible="progressDashboardVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <InsuranceUnaudited />
    </a-modal>
    <a-modal :title="title" v-model:visible="CRMDashboardVisible" :destroyOnClose="true" width="98%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <CrmDashboard />
    </a-modal>
    <a-modal :title="title" v-model:visible="receivableDashboardVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <ReceivableDashboard />
    </a-modal>
    <a-modal :title="title" v-model:visible="customerDashboardVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <CustomerDashboard />
    </a-modal>
    <a-modal :title="title" v-model:visible="salesAchievementVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <SalesAchievement />
    </a-modal>
  </div>
</template>

<script setup> 
import { ref } from 'vue'
 import IndicatorModule from '@/views/components/IndicatorModule'
import Documentary from '@/views/order/documentary'
import QuantityDashboard from '@/views/dataCenter/taskStatistics'
import TemporaryCustomer from '@/views/crmManagement/temporaryCustomer'
import SalesAchievement from './salesAchievement'
import CustomerDashboard from './customerDashboard'
import ReceivableDashboard from './receivableDashboard'
import CrmDashboard from './crmDashboard'
import OrderInvoice from './orderInvoice'
import OrderRefund from './orderRefund'
import FindCustomer from './findCustomer'
import AddCustomer from './addCustomer'
import Inquiry from './inquiry'
import ChannelConversion from './channelConversion'
import InsuranceUnaudited from './insuranceUnaudited'
import Prescription from '@/views/dataCenter/prescription'
import { useRouter } from 'vue-router'
const router = useRouter()

const addCustomer = ref(null)
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const inquiryVisible = ref(false)
const emit = defineEmits(['submit'])
const IndicatorVisible = ref(false)
// const documentaryVisible = ref(false)
const temporaryVisible = ref(false)
const salesAchievementVisible = ref(false)
const customerDashboardVisible = ref(false)
const receivableDashboardVisible = ref(false)
const CRMDashboardVisible = ref(false)
const progressDashboardVisible = ref(false)
const orderInvoiceVisible = ref(false)
const findCustomerVisible = ref(false)
const quantityDashboardVisible = ref(false)
const orderRefundVisible = ref(false)
const addCustomerVisible = ref(false)
const channelVisible = ref(false)
const prescriptionVisible = ref(false)
const title = ref('')

const submit = () => {
  emit('submit')
}
const openModal = (item) => {
  title.value = item.title
  switch (item.pageType) {
    case 71:
    const routeData1 = router.resolve({
          name: 'placeAnOrder',
          params: {
          }
        });
        // 打开新窗口或标签页
        window.open(routeData1.href, '_blank');
      // documentaryVisible.value = true
      break;
      break;
    case 72:
      findCustomerVisible.value = true
      break;
    case 73:
        const routeData = router.resolve({
          name: 'documentary',
          params: {
          }
        });
        // 打开新窗口或标签页
        window.open(routeData.href, '_blank');
      // documentaryVisible.value = true
      break;
    case 74:
      addCustomerVisible.value = true
      break;
    case 75:
      quantityDashboardVisible.value = true
      break;
    case 76:
    IndicatorVisible.value = true
      break;
    case 77:
      orderRefundVisible.value = true
      break;
    case 78:
      orderInvoiceVisible.value = true
      break;
    case 79:
    channelVisible.value = true
      break;
    case 710:
    prescriptionVisible.value = true
      break;
    case 711:
      progressDashboardVisible.value = true
      break;
    case 712:
      CRMDashboardVisible.value = true
      break;
    case 713:
      receivableDashboardVisible.value = true
      break;
    case 714:
      customerDashboardVisible.value = true
      break;
    case 715:
      salesAchievementVisible.value = true
      break;
  }
}
const AddCustomerOk = () => {
  addCustomer.value.onAddForm()
}
</script>

<style lang="less" scoped>
.optBox {
  width: 33%;
  font-size: 14px;
  color: #332;
  img {
    width: 50px;
    height: 45px;
  }
}
</style>