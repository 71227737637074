<template>
  <div>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle m-b1"><span>共&nbsp;</span><a>{{ data.length }}</a>个
        <a-button type="primary" class="m-l2" @click="searchAddShow = true" size="small">新增</a-button>
      </div>
      <a-table ref="tableRef" :columns="columns" :row-key="(record,index) =>index" bordered size="small" :data-source="dataSource" :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #customerName="{ record }">
          <a v-if="record.isSelf?.value ===1" @click="lookDetails(record)">
            <span v-if="record.customerName">{{ record.customerName }}</span>
            <span v-else>无</span>
          </a>
          <span v-else>
            <span v-if="record.customerName">{{ record.customerName }}</span>
            <span v-else>无</span>
          </span>
        </template>
        <template #mobile="{ record }">
          {{ record.mobile }}
          <span v-if="record.mobile && record.wechat">/</span>
          {{ record.wechat }}
        </template>
      </a-table>
    </div>
    <!-- 客户详情 -->
    <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose>
      <CustomerDetail v-model:customerId="customerId" :type="detailType" />
    </a-drawer>

    <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">

      <template #title>
        <span class="title">新增</span>
      </template>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item>

          <template #label>
            <div><span style="color: #f00">* </span>客户类型</div>
          </template>
          <a-select v-model:value="addForm.customerType.value" @change="customerTypeChange" :options="CustomerTypeEnum" placeholder="请选择客户类型">
          </a-select>
        </a-form-item>
        <a-form-item v-if="addForm.customerType.value === 2" label="企业名称" name="name" :rules="{
required: true,
message: '请填写企业名称',
trigger: 'change',
type: 'string',
}">
          <a-select v-model:value="addForm.name" placeholder="请输入企业名称" :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
            <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
              <span>{{ item.name }}</span>
              <span style="float: right">{{ item.operName }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="客户姓名" name="name" v-if="addForm.customerType.value === 1">
          <a-input v-model:value="addForm.name" placeholder="请填写客户姓名" />
        </a-form-item>
        <a-form-item label="客户来源" name="customerSourceType">
          <div style="display: flex;">
            <a-select allowClear v-model:value="addForm.customerSourceType" :options="$store.state.enumAll.customerSource" placeholder="选择客户来源" @change="customerSourceTypeChange">
            </a-select>
            <a-select v-if="addForm.customerSourceType === 2" :filter-option="false" show-search placeholder="请输入员工姓名" @search="employerValue">

              <template v-if="flagLoading" #notFoundContent>
                <a-spin size="small" />
              </template>
              <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name" @click="addEmployeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
            </a-select>
            <a-select v-if="addForm.customerSourceType === 3" placeholder="搜索并选择客户" @search="addEntpSearch" labelInValue :filterOption="false" show-search>

              <template v-if="fetching" #notFoundContent>
                <a-spin size="small" />
              </template>
              <a-select-option v-for="item in enterprises" :key="item.name" :value="item.id" @click="enterpriseChoose(item)">
                {{ item.name }}—{{ item.mobile }}
              </a-select-option>
            </a-select>
            <a-cascader v-if="addForm.customerSourceType === 4" v-model:value="addForm.addChannels" :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" placeholder="选择渠道" />
          </div>
        </a-form-item>
        <a-form-item label="客户电话" name="mobile">
          <a-input v-model:value="addForm.mobile" placeholder="请填写客户电话" />
        </a-form-item>
        <a-form-item label="客户微信">
          <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
        </a-form-item>
        <a-form-item label="客户地址">
          <a-input v-model:value="addForm.address" placeholder="请填写客户地址" />
        </a-form-item>
        <a-form-item name="certificateNumber">

          <template #label>
            {{ addForm.customerType.value === 2 ? "企业信用代码" : "客户身份证" }}
          </template>
          <a-input v-model:value="addForm.certificateNumber" placeholder="请填写" />
        </a-form-item>

        <a-form-item label="线索类型">
          <a-select allowClear v-model:value="addForm.clueType" :options="$store.state.enumAll.ClueTypeEnum" placeholder="请选择线索类型">
            <a-select-option v-for="item in $store.state.enumAll.ClueTypeEnum" :key="item.value">
              {{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { customerSearch } from '@/api/businessCenter/businessCenter'
import { getTableBody } from '@/utils/util'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
import { add } from '@/api/crmManagement/privateSea'
import { enterprise } from '@/api/system/setting'
import {
  enterpriseValidate,
  idNumberValidate,
  positiveNumberValidate
} from '@/utils/validate'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
const props = defineProps({
  typeNum: {
    type: Number,
    default: 0
  },
  keyword: {
    type: String,
    default: ''
  }
})
const enterprises = ref([])
const formRef = ref(null)
const store = useStore()
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const customerId = ref('')
const detailShow = ref(false)
const detailType = ref('')
const searchAddShow = ref(false)
const CustomerTypeEnum = ref([])
const mirrorAddForm = ref({})
const addForm = ref({
  name: '',
  mobile: '',
  wechat: '',
  customerSourceType: null,
  sourcePersonId: '',
  sourcePerson: '',
  customerType: { value: 1, label: '个人' }
})
const rules = ref({
  name: { required: true, message: '请填写客户名称', trigger: 'blur' },
  customerType: {
    required: true,
    message: '请选择客户类型',
    trigger: 'change',
    type: 'number'
  },
  customerSourceType: {
    required: true,
    message: '请选择客户来源',
    trigger: 'blur',
    type: 'number'
  },
  settlementMode: {
    required: true,
    message: '请选择结算方式',
    trigger: 'change',
    type: 'number'
  },
  certificateNumber: [
    { required: false, message: '请填写身份证号码', trigger: 'blur' },
    {
      pattern: idNumberValidate,
      message: '请填写正确的身份证号码',
      trigger: 'blur'
    }
  ],
  mobile: [
    { required: false, message: '请填写电话号码', trigger: 'blur' },
    {
      pattern: positiveNumberValidate,
      message: '请填写正确的电话号码',
      trigger: 'blur'
    }
  ]
})

// 新增
const onAddForm = () => {
  if (addForm.value.mobile !== '' || addForm.value.wechat !== '') {
    formRef.value
      .validate()
      .then(() => {
        const channelArr = addForm.value.addChannels
        if (
          channelArr !== undefined &&
          channelArr !== null &&
          channelArr.length > 1
        ) {
          addForm.value.channelId = channelArr[0]
          addForm.value.channelSubId = channelArr[1]
        }
        delete addForm.value.addChannels
        add({ customer: addForm.value }).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            searchAddShow.value = false
            addForm.value = JSON.parse(mirrorAddForm.value)
          }
        })
      }).catch((err) => {
        console.log(err)
      })
  } else message.error('微信号和电话必须填写其中一个')
}
  // 客户类型选择
  const customerTypeChange = (e, v) => {

      addForm.value.customerType = v
        if (addForm.value.customerType.value === 2) {
          rules.value.name.message = '请填写企业名称'
          rules.value.certificateNumber[1].pattern = enterpriseValidate
          rules.value.certificateNumber[1].message = '请输入正确的企业信用代码'
        } else {
          rules.value.name.message = '请填写客户姓名'
          rules.value.certificateNumber[1].pattern = idNumberValidate
          rules.value.certificateNumber[1].message = '请输入正确的身份证号'
        }
      }
const entpSelect = async (val, option) => {
        addForm.value.name = val
        addForm.value.certificateNumber = option.key
      }
const enterpriseChoose = item => {
        addForm.value.introduceCustomerName = item.name
        addForm.value.introduceCustomerId = item.id
      }
const timeOut = ref(null)
// 新增-企业客户列表获取
const entpSearch = async (val) => {
  if (val.length > 3) {
    const filterVal = val
      .replace(new RegExp('[a-z]', 'ig'), '')
      .replace(new RegExp('[0-9]', 'g'), '')
    if (filterVal.length < 4) {
      return
    }
    if (timeOut.value !== null) {
      clearTimeout(timeOut.value)
    }
    timeOut.value = setTimeout(() => {
      enterprise({ name: val }).then((res) => {
        if (res.code === 10000) {
          enterprises.value = res.data
          timeOut.value = null
        } else {
          enterprises.value = []
        }
      })
    }, 500)
  } else {
    enterprises.value = []
  }
}

const lookDetails = (record) => {
  if (record.customerClass) {
    detailType.value = String(record.customerClass.value - 1)
  } else {
    detailType.value = '2'
  }
  detailShow.value = true
  customerId.value = record.customerId
}
const loadData = () => {
  loading.value = true
  customerSearch({
    mobile: props.typeNum === 1 ? props.keyword : '',
    wechat: props.typeNum === 2 ? props.keyword : '',
    customerName: props.typeNum === 3 ? props.keyword : '',

  }).then(res => {
    // console.log(res)  // 主要动态数据
    if (res.code === 10000) {
      data.value = res.data
      dataSource.value = data.value.slice(0, pageSize.value)
    }
  }).finally(() => {
    loading.value = false
  })
}
const columns = ref([
  { title: '序号', dataIndex: 'sort', slots: { customRender: 'sort' }, align: 'center', width: 80 },
  { title: '客户名称', dataIndex: 'customerName', align: 'center', slots: { customRender: 'customerName' } },
  { title: '电话/微信', dataIndex: 'mobile', align: 'center', slots: { customRender: 'mobile' } },
  { title: '客户类型', dataIndex: 'customerType.label', align: 'center' },
  { title: '线索分类', dataIndex: 'clueType.label', align: 'center' },
  { title: '所在位置', dataIndex: 'customerClass.label', align: 'center' },
  { title: '业务员', dataIndex: 'saleName', align: 'center' },
  { title: '创建时间', dataIndex: 'createTime', align: 'center' },
  { title: '领取时间', dataIndex: 'enterPrivateTime', align: 'center' },
  { title: '合作时间', dataIndex: 'enterCoopTime', align: 'center' },
  { title: '结算方式', dataIndex: 'settlementMode.label', align: 'center' },
  { title: '首次负责人', dataIndex: 'firstSaleName', align: 'center' },
])
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)

}
onMounted(() => {
 mirrorAddForm.value = JSON.stringify(addForm.value)
  loadData()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
  CustomerTypeEnum.value = JSON.parse(
    JSON.stringify(store.state.enumAll.CustomerTypeEnum)
  )
  if (CustomerTypeEnum.value[0].value === '') CustomerTypeEnum.value.splice(0, 1)
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>