<template>
<div>
  <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
  <div class="m-t1 tableBox">
      <div class="sys-screenTitle">{{ remakItem.invoiceCarrier }}   {{ remakItem.taxRate }}%-{{ remakItem.taxItem }} 
        <span class="m-l1">共 <a>{{data.length}}</a> 辆</span>
        <span class="m-l1">开票总金额：<a>￥{{remakItem.amt}}元</a></span>
        </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}{{ record.model }}
        </template>
        <template #startAddress="{ record }">
          {{ record.startAddress }}
          <div>{{ record.endAddress }}</div>
        </template>
        <template #settlementStatus="{ record }">
          {{ record.orderSettlement?.label }}  {{ record.settlementStatus?.label }}
        </template>
        <template #refunder="{ record }">
          {{ record.refunder }}&nbsp;{{ record.refunderMobile }}
        </template>
      </a-table>
    </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { orderInvoiceDetails } from '@/api/businessCenter/businessCenter'
import { getTableBody} from '@/utils/util'
const props = defineProps({
  customerId:{
    type: String,
    default: ''
  },
  remakItem:{
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const searchMsg = ref({})
const totalAmt = ref(0)

const searchAdd = (e) => {
  searchMsg.value = e
  getOrderRefundDetails()
}
const resetBtn = () => {
  searchMsg.value = {}
  getOrderRefundDetails()
}
const getOrderRefundDetails = () => {
  pageSize.value = 50
  loading.value = true
  orderInvoiceDetails({
    customerId:props.customerId,
    invoiceCarrier:props.remakItem.invoiceCarrier,
    taxRate:props.remakItem.taxRate,
    ...searchMsg.value
  }).then( res => {
    if(res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0,pageSize.value)
  }).finally(()=>{
    loading.value = false
  })
}
const theBottom = () => {
  if(dataSource.value.length === 0) return
  if(pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0,pageSize.value)
}
onMounted(()=>{
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'),theBottom )
})
const iptData = ref([
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    width: 200,
    opt:[
      { name: '未结算', id: 0 },
      { name: '部分结算', id: 1 },
      { name: '已结算', id: 2 }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
    width: 150
  },
  {
    title: '分单员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '完成时间',
    dataIndex: 'orderFinishTime',
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'orderSettlement',
    slots: {
      customRender: 'settlementStatus'
    },
    align: 'center',
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>